import React, {
  useRef,
  useState,
  useEffect,
  createContext,
  useContext,
} from "react";
import emailjs from "@emailjs/browser";

import Fade from "react-reveal/Fade";

import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../actions/auth";

import { Text } from "../components/languages/Text/Text";

import { languageOptions } from "../components/languages";
import {
  LanguageContext,
  LanguageProvider,
} from "../components/container/Language";

// import { Link } from "react-router-dom";

import "video-react/dist/video-react.css";
import { Player } from "video-react";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import CryptoTracker from "../components/CryptoTracker";

import { useQuery } from "react-query";

import homeStore from "../utils/homeStore";
import GetCryptoPrice from "../components/GetCryptoPrice";
import { create } from "zustand";

import { getRewards, editRewards } from "../actions/rewards";

import { NotificationManager } from "react-notifications";

import { setAlert } from "../actions/alert";

function Home({
  auth: { isAuthenticated },
  logout,
  editRewards,
  getRewards,
  rewards,
  setAlert,
  alert,
}) {
  let log_btn = "";
  if (isAuthenticated) {
    log_btn = (
      <p
        className="flex items-center mr-2 text-xl cursor-pointer text-underlined underline a-langdis"
        onClick={logout}
      >
        LogOut
      </p>
    );
  } else {
    log_btn = (
      <Link
        className="flex items-center mr-2 text-xl cursor-pointer text-underlined underline a-langdis"
        to="/login"
      >
        Admin
      </Link>
    );
  }

  // let log_btn = (
  //   <p
  //     className="flex items-center mr-2 text-xl cursor-pointer text-underlined underline a-langdis"

  //   >
  //     LogIn
  //   </p>
  // );

  useEffect(() => {
    //  console.log(alert);
    if (isAuthenticated && alert.length > 0) {
      NotificationManager.success(alert.pop().msg, "Success", 3000);
    }
  }, [alert]);

  const emailRef = useRef();
  const nameRef = useRef();
  const phoneRef = useRef();
  const subjectRef = useRef();
  const messageRef = useRef();

  const myRef = useRef();
  const progressRef = useRef();

  const [loading, setLoading] = useState(false);

  const [dropdownShow, setDropdownShow] = useState(false);

  const [blueWidth, setBlueWidth] = useState("0%");
  const [redWidth, setRedWidth] = useState("0%");
  const [greenWidth, setGreenWidth] = useState("0%");
  const [yellowWidth, setYellowWidth] = useState("0%");
  const [orangeWidth, setOrangeWidth] = useState("0%");

  const [coinClass, setCoinClass] = useState(false);
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  const queryClient = new QueryClient();

  const [holderAmount, setHolderAmount] = useState(Number(1));
  const [holderDollars, setHolderDollars] = useState(Number(1.5));
  const [totalDollars, setTotalDollars] = useState(Number(1.5));
  const [donationDollars, setDonationDollars] = useState(Number(0));
  const [donationAmount, setDonationAmount] = useState(Number(0));
  const [weeklyDollars, setWeeklyDollars] = useState(Number(0));
  const [weeklyAmount, setWeeklyAmount] = useState(Number(0));

  const [btcTotal, setBtcTotal] = useState(Number(0));
  const [btcbTotal, setBtcbTotal] = useState(Number(0));

  // const BtcContext = createContext();
  const [btcPrice, setBtcPrice] = useState(Number());
  const [btcbPrice, setBtcbPrice] = useState(Number());

  const [intervalId, setIntervalId] = useState(null);

  const updateBtcPrice = (price) => {
    setBtcPrice(price);
  };
  const updateBtcbPrice = (price) => {
    setBtcbPrice(price);
  };

  useEffect(() => {
    // console.log("totalDollars", totalDollars);
    // console.log("btcPrice", btcPrice);
    // console.log("btcbPrice", btcbPrice);
    setBtcTotal(Number(totalDollars) / Number(btcPrice * 1));
    setBtcbTotal(Number(totalDollars) / Number(btcbPrice * 1));
  }, [btcPrice, btcbPrice]);

  const numberFormat = (num, options) => {
    let temp = 2;
    if (num < 1 && num > 0.0001) {
      temp = 4;
    }
    if (num < 0.0001) {
      temp = 8;
    }
    let defaultOptions = {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: temp,
      minimumFractionDigits: 2,
      notation: "standard",
      compactDisplay: "long",
    };
    return new Intl.NumberFormat("en-US", {
      ...defaultOptions,
      ...options,
    }).format(num);
  };

  useEffect(() => {
    emailjs.init("k5IrluD-7a4J33Pet");
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      // console.log("entry", entry);
      // console.log("entry.isIntersecting", entry.isIntersecting);

      if (!entry.isIntersecting) {
        setCoinClass("w-full md:w-1/4 m-auto coin-img wow fadeInRight");
      } else {
        setCoinClass(
          "w-full md:w-1/4 m-auto coin-img wow fadeInRight coin-img-animation"
        );
      }
    });

    observer.observe(myRef.current);

    setInterval(() => {}, 10000);
  }, []);

  useEffect(() => {
    setHolderDollars(Number(holderAmount) * 1.5);
  }, [holderAmount]);

  useEffect(() => {
    setTotalDollars(
      Number(holderDollars) + Number(donationDollars) + Number(weeklyDollars)
    );
  }, [holderDollars, donationDollars, weeklyDollars]);

  useEffect(() => {
    // setBtcTotal(Number(totalDollars) / 27640);
    // setBtcbTotal(Number(totalDollars) / 27508);
    // console.log("totalDollars:", totalDollars);
    setBtcTotal(Number(totalDollars) / Number(btcPrice * 1));
    setBtcbTotal(Number(totalDollars) / Number(btcbPrice * 1));
  }, [totalDollars]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      // console.log("entry", entry);
      // console.log("entry.isIntersecting", entry.isIntersecting);

      if (entry.isIntersecting) {
        setBlueWidth("30.9%");
        setRedWidth("15.1%");
        setGreenWidth("15.1%");
        setYellowWidth("9.8%");
        setOrangeWidth("30.9%");
      } else {
        setBlueWidth("0%");
        setRedWidth("0%");
        setGreenWidth("0%");
        setYellowWidth("0%");
        setOrangeWidth("0%");
      }
    });

    observer.observe(progressRef.current);

    // console.log(isAuthenticated);
    // if (!isAuthenticated) {
    //   setInterval(() => {
    //     getRewards();
    //   }, 10000);
    // } else {
    //   getRewards();
    // }
  }, []);

  useEffect(() => {
    if (isAuthenticated === true) clearInterval(intervalId);
    else {
      const temp = setInterval(() => {
        getRewards();
        console.log("getting rewards");
      }, 5000);
      setIntervalId(temp);
      return () => {
        clearInterval(temp);
      };
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (rewards?.rewards) {
      const {
        donationAmount,
        donationDollars,
        holdersAmount,
        holdersDollars,
        weeklyAmount,
        weeklyDollars,
        totalDollars,
      } = rewards?.rewards;
      setDonationAmount(donationAmount);
      setDonationDollars(donationDollars);
      setHolderAmount(holdersAmount);
      setHolderDollars(holdersDollars);
      setWeeklyAmount(weeklyAmount);
      setWeeklyDollars(weeklyDollars);
      setTotalDollars(totalDollars);
    }
  }, [rewards]);

  const updateRewards = () => {
    editRewards({
      weeklyDollars: weeklyDollars,
      donationDollars: donationDollars,
      donationAmount: donationAmount,
      holdersDollars: holderDollars,
      totalDollars: totalDollars,
      holdersAmount: holderAmount,
      weeklyAmount: weeklyAmount,
    });
  };

  // const handleDropdown = () => {
  //   setDropdownShow(!dropdownShow);
  // };

  // const handleLangSelect = (lang) => {
  //   var langSimple = "";
  //   if (lang == "English") {
  //     langSimple = "en";
  //   } else if (lang == "Spanish") {
  //     langSimple = "ge";
  //   }
  //   setDropdownShow(false);
  //   userLanguageChange(langSimple);
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const serviceId = "service_ld9gf11";
  //   const templateId = "template_n4gbhbp";
  //   console.log({
  //     from_name: nameRef.current.value,
  //     from_email: emailRef.current.value,
  //     from_phone: phoneRef.current.value,
  //     from_subject: subjectRef.current.value,
  //     from_message: messageRef.current.value,
  //   });
  //   try {
  //     setLoading(true);
  //     await emailjs.send(serviceId, templateId, {
  //       from_name: nameRef.current.value,
  //       from_email: emailRef.current.value,
  //       from_phone: phoneRef.current.value,
  //       from_subject: subjectRef.current.value,
  //       from_message: messageRef.current.value,
  //     });
  //     alert("Successfully sent!");
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
 
  return (
    <div className="">
      <script src="js/main.js" type="text/javascript"></script>
      <div className="preloader d-none">
        <div className="loader">
          <div className="ytp-spinner">
            <div className="ytp-spinner-container">
              <div className="ytp-spinner-rotator">
                <div className="ytp-spinner-left">
                  <div className="ytp-spinner-circle"></div>
                </div>
                <div className="ytp-spinner-right">
                  <div className="ytp-spinner-circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header className="header navbar-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="#">
                  <p className="a-navlogo">
                    <Text tid={"projectName"}></Text>
                  </p>
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse sub-menu-bar"
                  id="navbarSupportedContent"
                >
                  <ul id="nav" className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <a className="page-scroll active" href="#home">
                        <Text tid={"home"}></Text>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#about">
                        <Text tid={"about"}></Text>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#service">
                        <Text tid={"partner"}></Text>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#roadmap">
                        <Text tid={"roadmap"}></Text>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#team">
                        <Text tid={"team"}></Text>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="page-scroll" href="#rewards">
                        <Text tid={"rewards"}></Text>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="a-position-relative">
                  {/* <p
                    className="flex items-center mr-2 text-xl cursor-pointer text-underlined underline a-langdis"
                    id="dropdownDefaultButton"
                    data-dropdown-toggle="dropdown"
                    data-dropdown-trigger="hover"
                    onClick={() => {
                      handleDropdown();
                    }}
                  > */}
                  {/* <Link
                    className="flex items-center mr-2 text-xl cursor-pointer text-underlined underline a-langdis"
                    to="/login"
                  >
                    LogIn
                  </Link> */}
                  {log_btn}
                  {/* {userLanguage == "en" ? "English" : "Spanish"} */}
                  {/* <svg
                      className="w-2.5 h-2.5 ml-2.5 a-svg"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg> */}
                  {/* </p> */}
                  <div className="relative"></div>
                  {/* {dropdownShow && (
                    <div
                      id="dropdown"
                      className="z-10 block a-dropdown bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                    >
                      <ul
                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownDefaultButton"
                      >
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-black"
                            onClick={() => {
                              handleLangSelect("English");
                            }}
                          >
                            <Text tid={"en"} />
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-black"
                            onClick={() => {
                              handleLangSelect("Spanish");
                            }}
                          >
                            <Text tid={"ge"} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  )} */}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <section
        id="home"
        className="hero-section"
        style={{ width: "100%", overflow: "hidden" }}
      >
        <div className="shapes">
          <div className="shape shape-1"></div>
          <div className="shape shape-2"></div>
          <div className="shape shape-3"></div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-7">
              <div className="hero-content-wrapper">
                <h1 className="text-white wow fadeInDown" data-wow-delay=".2s">
                  <Text tid={"header-intro"}></Text>
                </h1>
                <p className="text-white wow fadeInLeft" data-wow-delay=".4s">
                  <Text tid={"header-description"}></Text>
                </p>
                <p className="mb-4 text-white color-gold">
                  Hope Mountain: &nbsp;
                  <a
                    href="https://bscscan.com/address/0xFb762E89abF4844C128e33Ce0de0b8936b685bf2"
                    rel="nofollow"
                    target="_blank"
                    className="color-gold"
                    style={{ color: "white", textDecoration: "underline" }}
                  >
                    0xFb762E89abF4844C128e33Ce0de0b8936b685bf2
                  </a>
                </p>
                <a
                  href="#"
                  className="theme-btn wow fadeInUp"
                  data-wow-delay=".6s"
                  target="_blank"
                >
                  Presale to be announced
                </a>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5">
              <div
                className="hero-img"
                style={{ width: "80%", float: "right" }}
              >
                <img
                  src="img/dark-logo.png"
                  alt=""
                  className={coinClass}
                  ref={myRef}
                  data-wow-delay=".5s"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section id="feature" className="feature-section pt-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8 mx-auto">
              <div className="section-title text-center mb-55">
                <h2 className="mb-20 wow fadeInUp" data-wow-delay=".2s">
                  <Text tid={"solution"}></Text>
                </h2>
              </div>
            </div>
          </div>
          <div className="features-wrapper" ref={progressRef}>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-6 mx-auto token-div">
                <img
                  src="img/light-logo.png"
                  alt="image"
                  className="wow fadeInLeft"
                  data-wow-delay=".4s"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-xl-6 col-lg-6 mx-auto">
                <div className="color-white">
                  <label className="label">
                    <Text tid={"t_presale"} />{" "}
                    <span className="color-green bolder text-2xl">(30.9%)</span>
                  </label>
                  <div className="animated-progress progress-blue">
                    <span
                      data-progress="70"
                      style={{ width: blueWidth }}
                    ></span>
                  </div>
                  <label className="label">
                    <Text tid={"t_exchange"} />{" "}
                    <span className="color-green bolder text-2xl">(15.1%)</span>
                  </label>
                  <div className="animated-progress progress-red">
                    <span data-progress="60" style={{ width: redWidth }}></span>
                  </div>
                  <label className="label">
                    <Text tid={"t_marketing"} />{" "}
                    <span className="color-green bolder text-2xl">(15.1%)</span>
                  </label>
                  <div className="animated-progress progress-green">
                    <span
                      data-progress="70"
                      style={{ width: greenWidth }}
                    ></span>
                  </div>
                  <label className="label">
                    <Text tid={"t_team"} />{" "}
                    <span className="color-green bolder text-2xl">(9.8%)</span>
                  </label>
                  <div className="animated-progress progress-yellow">
                    <span
                      data-progress="85"
                      style={{ width: yellowWidth }}
                    ></span>
                  </div>
                  <label className="label">
                    <Text tid={"t_rewards"} />{" "}
                    <span className="color-green bolder text-2xl">(30.9%)</span>
                  </label>
                  <div className="animated-progress progress-orange">
                    <span
                      data-progress="85"
                      style={{ width: orangeWidth }}
                    ></span>
                  </div>
                </div>
                <hr
                  className="mt-2 mb-2 text-black color-green"
                  style={{ color: "white" }}
                />
                <ul className="mt-4 common-text  text-sm md:text-lg md:text-xl">
                  <ul
                    className="pl-4"
                    style={{ color: "black", fontSize: "20px" }}
                  >
                    <li style={{ listStyle: "square" }}>
                      Total Supply: <b>10 Billion</b>
                    </li>
                    <li style={{ listStyle: "square" }}>
                      Name: <b>Hope Time Token</b>
                    </li>
                    <li style={{ listStyle: "square" }}>
                      Ticker: <b>HTME</b>
                    </li>
                    <li style={{ listStyle: "square" }}>
                      Decimals: <b>4</b>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about-section pt-150 pb-20">
        <div className="container">
          <div className="row align-items-center position-relative">
            <div className="col-xl-6 col-lg-6">
              <div className="about-content mb-30">
                <div className="section-title mb-40">
                  <h2 className="wow fadeInUp" data-wow-delay=".2s">
                    <Text tid={"aboutUs"}></Text>
                  </h2>
                </div>
                {/* <p className="mb-15 wow fadeInUp" data-wow-delay=".4s">
                  <Text tid={"about1"}></Text>
                </p>
                <p className="mb-35 wow fadeInUp" data-wow-delay=".6s">
                  <Text tid={"about2"}></Text>
                </p>
                <p className="mb-35 wow fadeInUp" data-wow-delay=".6s">
                  <Text tid={"about3"}></Text>
                </p>
                <p className="mb-35 wow fadeInUp" data-wow-delay=".6s">
                  <Text tid={"about4"}></Text>
                </p>
                <a
                  href="#"
                  className="theme-btn theme-btn-2 wow fadeInRight"
                  data-wow-delay=".8s"
                >
                  <Text tid={"learnmore"}></Text>
                </a> */}
                <video
                  width="100%"
                  height="380"
                  controls
                  className="mt-4 m-auto"
                >
                  <source src="video/bg.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="about-img mb-30">
                <img
                  src="img/hopemountain.png"
                  alt="image"
                  className="wow fadeInLeft"
                  data-wow-delay=".4s"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <img src="img/light-logo.png" className="absolute-img"></img>
          </div>
        </div>
      </section>

      <section id="service" className="service-section pt-150 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-8 col-md-10 mx-auto">
              <div className="section-title text-center mb-55">
                <h2
                  className="mb-20 wow fadeInDown"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInDown",
                  }}
                >
                  <Text tid={"partner-with-us"}></Text>
                </h2>
                {/* <p
                  className="wow fadeInUp"
                  data-wow-delay=".4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInDown",
                  }}
                >
                  <Text tid={"partner-desc"}></Text>
                </p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="single-service wow fadeInUp"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="service-icon mb-30">
                  <i className="lni lni-money-protection"></i>
                </div>
                <h4>
                  <Text tid={"partner1"}></Text>
                </h4>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="single-service wow fadeInUp"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="service-icon mb-30">
                  <i className="lni lni-alarm"></i>
                </div>
                <h4>
                  <Text tid={"partner2"}></Text>
                </h4>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="single-service wow fadeInUp"
                data-wow-delay=".6s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.6s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="service-icon mb-30">
                  <i className="lni lni-reload"></i>
                </div>
                <h4>
                  <Text tid={"partner3"}></Text>
                </h4>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="single-service wow fadeInUp"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="service-icon mb-30">
                  <i className="lni lni-cog"></i>
                </div>
                <h4>
                  <Text tid={"partner4"}></Text>
                </h4>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div
                className="single-service wow fadeInUp"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="service-icon mb-30">
                  <i className="lni lni-bolt-alt"></i>
                </div>
                <h4>
                  <Text tid={"partner5"}></Text>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="roadmap"
        className="roadmap-section pt-100 pb-70 img-bg"
        style={{
          backgroundColor: "black",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7 col-md-9 mx-auto">
              <div className="section-title text-center mb-55">
                <h2
                  className="text-white mb-20 wow fadeInUp"
                  data-wow-delay=".4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInDown",
                  }}
                >
                  <Text tid={"roadmap"}></Text>
                </h2>
                <p
                  className="text-white wow fadeInUp"
                  data-wow-delay=".6s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.6s",
                    animationName: "fadeInDown",
                  }}
                >
                  <Text tid={"roadmap-desc"}></Text>
                </p>
              </div>
            </div>
          </div>
          <div className="roadmap-wrapper justify-content-center">
            <div className="roadmap-item">
              <span className="circle"></span>
              <span>
                <Text tid={"roadmap1-when"}></Text>
              </span>
              <p>
                <ul
                  style={{
                    height: "300px",

                    textAligh: "center",
                  }}
                >
                  <li className="color-gold d-flex justify-content-center">
                    <span className="mr-1 color-gold font-bolder">
                      &#x2713;
                    </span>
                    Project Idea{" "}
                  </li>
                  <li className="color-gold d-flex justify-content-center">
                    <span className="mr-1 color-gold font-bolder">
                      &#x2713;
                    </span>
                    Logo Design{" "}
                  </li>
                  <li className="color-gold d-flex justify-content-center">
                    <span className="mr-1 color-gold font-bolder">
                      &#x2713;
                    </span>
                    Build Team and Video{" "}
                  </li>
                </ul>
              </p>
            </div>
            <div className="roadmap-item">
              <span className="circle"></span>
              <span>
                <Text tid={"roadmap2-when"}></Text>
              </span>
              <p>
                <ul
                  style={{
                    height: "300px",

                    textAligh: "center",
                  }}
                >
                  <li className="color-gold d-flex justify-content-center">
                    <span className="mr-1 color-gold font-bolder">
                      &#x2713;
                    </span>
                    Website Creation{" "}
                  </li>
                  <li className="color-gold  d-flex justify-content-center">
                    <span className="mr-1 color-gold font-bolder">
                      &#x2713;
                    </span>
                    Create Contract
                  </li>
		  <li className="">
                    
                    Gempad Presale
                  </li>
 		  <li className="">
                   
                    Audit
                  </li>
		  <li>KYC</li>
                  <li>Social Media</li>
                </ul>
              </p>
            </div>
            <div className="roadmap-item">
              <span className="circle"></span>
              <span>
                <Text tid={"roadmap3-when"}></Text>
              </span>
              <p>
                <ul
                  style={{
                    height: "300px",

                    textAligh: "center",
                  }}
                >
                  <li>Presale Token Launch, Add Liquidity, Lock 3 years </li>
                  <li>Hope Time Token Launch </li>
                  <li>Pancakeswap and Uniswap</li>
                  <li>500 Holders</li>
                  <li>Accumulate BTC for token rewards</li>
                  <li>Staking 30 Days</li>
                  <li>Accumulate BTC Rewards</li>
                </ul>
              </p>
            </div>
            <div className="roadmap-item">
              <span className="circle"></span>
              <span>
                <Text tid={"roadmap4-when"}></Text>
              </span>
              <p>
                <ul
                  style={{
                    height: "300px",

                    textAligh: "center",
                  }}
                >
                  <li>Marketing </li>
                  <li>Coinmarketcap, Coingecko </li>
                  <li>Staking 60 Days To 1 year</li>
                  <li>Dex Listing </li>
                  <li>2000 Holders </li>
                  <li>Megaburn 2.5 Billion </li>
                  <li>Distribute BTC rewards </li>
                </ul>
              </p>
            </div>
            <div className="roadmap-item">
              <span className="circle"></span>
              <span>
                <Text tid={"roadmap5-when"}></Text>
              </span>
              <p>
                <ul
                  style={{
                    height: "300px",

                    textAligh: "center",
                  }}
                >
                  <li>Marketing </li>
                  <li>10,000 Holders </li>
                  <li>Develop farming, Swap and Wallet </li>
                  <li>Tier 1 Listing </li>
                  <li>Develop Lending Protocol and Payments </li>
                  <li>Megaburn 2.5 Billion </li>
                  <li>Tokenized Real Estate </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="team" className="team-section pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7 mx-auto">
              <div className="section-title text-center mb-55">
                <h2
                  className="mb-20 wow fadeInUp"
                  data-wow-delay=".2s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInDown",
                  }}
                >
                  <Text tid={"meetTeam"}></Text>
                </h2>
                <p
                  className="mb-55 wow fadeInLeft"
                  data-wow-delay=".4s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInDown",
                  }}
                >
                  <Text tid={"meetTeamDesc"}></Text>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="single-team text-center mb-120 wow fadeInUp"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="team-img mb-20">
                  <img src="img/team-1.png" alt="" className="team-img" />
                  {/* <div className="team-social-links">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="lni lni-facebook-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-twitter-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-linkedin-original"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="team-info">
                    <h5>
                      <Text tid={"team1-name"}></Text>
                    </h5>
                    <span>
                      <Text tid={"team1-role"}></Text>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="single-team text-center mb-120 wow fadeInUp"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="team-img mb-20">
                  <img src="img/team-2.png" alt="" className="team-img" />
                  {/* <div className="team-social-links">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="lni lni-facebook-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-twitter-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-linkedin-original"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="team-info">
                    <h5>
                      <Text tid={"team2-name"}></Text>
                    </h5>
                    <span>
                      <Text tid={"team2-role"}></Text>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="single-team text-center mb-120 wow fadeInUp"
                data-wow-delay=".6s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.6s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="team-img mb-20">
                  <img src="img/team-3.png" alt="" className="team-img" />
                  {/* <div className="team-social-links">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="lni lni-facebook-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-twitter-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-linkedin-original"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="team-info">
                    <h5>
                      <Text tid={"team3-name"}></Text>
                    </h5>
                    <span>
                      <Text tid={"team3-role"}></Text>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="single-team text-center mb-120 wow fadeInUp"
                data-wow-delay=".8s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.8s",
                  animationName: "fadeInDown",
                }}
              >
                <div className="team-img mb-20">
                  <img src="img/team-4.png" alt="" className="team-img" />
                  {/* <div className="team-social-links">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="lni lni-facebook-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-twitter-filled"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="lni lni-linkedin-original"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="team-info">
                    <h5>
                      <Text tid={"team4-name"}></Text>
                    </h5>
                    <span>
                      <Text tid={"team4-role"}></Text>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="countdown-section pt-70 pb-70 img-bg"
        style={{
          backgroundImage: "url('img/hope.png')",
          color: "black",
        }}
        id="rewards"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="countdown-img">
                {/* <img
                  src="img/countdown-img.svg"
                  alt=""
                  className="wow fadeInLeft"
                  data-wow-delay=".4s"
                /> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="countdown-content-wrapper">
                <div className="countdown" style={{ display: "none" }}>
                  <ul id="countdown-example">
                    <li>
                      <span className="days">89</span>
                      <p className="days_text">DAYS</p>
                    </li>
                    <li>
                      <span className="hours">53</span>
                      <p className="hours_text">HOUR</p>
                    </li>
                    <li>
                      <span className="minutes">34</span>
                      <p className="minutes_text">MINS</p>
                    </li>
                    <li>
                      <span className="seconds">08</span>
                      <p className="seconds_text">SECS</p>
                    </li>
                  </ul>
                </div>
                <div className="countdown-content">
                  <h2 className=" mb-35 wow fadeInUp" data-wow-delay=".2s">
                    <Text tid={"howWorks"}></Text>
                  </h2>
                  <p className=" mb-30 wow fadeInUp" data-wow-delay=".4s">
                    Have you ever dreamed about owning Bitcoin(BTC).
                    <br /> Maybe you have asked yourself how I can own Bitcoin
                    (BTC). <br />
                    WELL, HERE IS HOW.
                  </p>
                  <p>
                    <QueryClientProvider client={queryClient}>
                      <CryptoTracker cryptoName="bitcoin" />
                    </QueryClientProvider>
                  </p>
                  <div style={{ display: "none" }}>
                    <QueryClientProvider client={queryClient}>
                      <GetCryptoPrice
                        cryptoName={"bitcoin"}
                        updateBtcPrice={updateBtcPrice}
                      ></GetCryptoPrice>

                      <GetCryptoPrice
                        cryptoName={"wrapped-bitcoin"}
                        updateBtcPrice={updateBtcbPrice}
                      ></GetCryptoPrice>
                    </QueryClientProvider>
                  </div>

                  {/* <br/> */}
                  <table
                    className="table table-bordered table-dark"
                    id="rewards-table"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Countributors</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Dollars</th>
                        <th scope="col" className="table-th">
                          BTC
                        </th>
                        <th scope="col" className="table-th">
                          BTCB
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Donations</th>
                        <td>
                          <input
                            placeholder="Input Donation"
                            type="number"
                            min={0}
                            disabled={!isAuthenticated}
                            onChange={(e) => {
                              setDonationAmount(e.target.value);
                            }}
                            value={
                              isAuthenticated
                                ? donationAmount
                                : rewards?.rewards?.donationAmount
                            }
                          ></input>
                        </td>
                        <td>
                          <input
                            placeholder="Input Donation Dollars"
                            type="number"
                            min={0}
                            id="r_donation_dollars"
                            onChange={(e) => {
                              setDonationDollars(e.target.value);
                            }}
                            disabled={!isAuthenticated}
                            value={
                              isAuthenticated
                                ? donationDollars
                                : rewards?.rewards?.donationDollars
                            }
                          ></input>
                        </td>
                        <td className="table-th"></td>
                        <td className="table-th"></td>
                      </tr>
                      <tr>
                        <th scope="row">Holders</th>
                        <td>
                          <input
                            placeholder="Input Holder Number"
                            type="number"
                            min={0}
                            id="holder_amount"
                            value={
                              isAuthenticated
                                ? holderAmount
                                : rewards?.rewards?.holderAmount
                            }
                            onChange={(e) => {
                              setHolderAmount(e.target.value);
                            }}
                            disabled={!isAuthenticated}
                          ></input>
                        </td>
                        <td id="r_holder_dollars">{holderDollars}</td>
                        <td className="table-th"></td>
                        <td className="table-th"></td>
                      </tr>
                      <tr>
                        <th scope="row">Weekly %</th>
                        <td>
                          <input
                            placeholder="Type Weekly Percentage"
                            type="number"
                            min={0}
                            disabled={!isAuthenticated}
                            onChange={(e) => {
                              setWeeklyAmount(e.target.value);
                            }}
                            value={
                              isAuthenticated
                                ? weeklyAmount
                                : rewards?.rewards?.weeklyAmount
                            }
                          ></input>
                        </td>
                        <td>
                          <input
                            placeholder="Type Weekly Dollars"
                            type="number"
                            min={0}
                            id="r_weekly_dollars"
                            onChange={(e) => {
                              setWeeklyDollars(e.target.value);
                            }}
                            disabled={!isAuthenticated}
                            value={
                              isAuthenticated
                                ? weeklyDollars
                                : rewards?.rewards?.weeklyDollars
                            }
                          ></input>
                        </td>
                        <td className="table-th"></td>
                        <td className="table-th"></td>
                      </tr>
                      <tr>
                        <th scope="row">Totals</th>
                        <td></td>
                        <td id="r_totals">
                          {isAuthenticated
                            ? totalDollars
                            : rewards?.rewards?.totalDollars}
                        </td>
                        <td className="table-th">{btcTotal.toFixed(4)}</td>
                        <td className="table-th">{btcbTotal.toFixed(4)}</td>
                      </tr>
                    </tbody>
                  </table>
                  {isAuthenticated && (
                    <a
                      className="theme-btn wow fadeInUp mb-4"
                      data-wow-delay=".6s"
                      onClick={() => {
                        updateRewards();
                      }}
                    >
                      Save Rewards Detail
                    </a>
                  )}
                  <p className=" mb-30 wow fadeInUp" data-wow-delay=".4s">
                    1. A weekly % will go to buying Bitcoin (BTC ).
                  </p>
                  <p className=" mb-30 wow fadeInUp" data-wow-delay=".4s">
                    2. Hold Hope Time Token 30 days or longer.
                  </p>
                  <p className=" mb-30 wow fadeInUp" data-wow-delay=".4s">
                    3. I will donate 1 .5 dollars for every new holder to buy
                    BTC.
                  </p>
                  <p className=" mb-30 wow fadeInUp" data-wow-delay=".4s">
                    4. A % in donations will be used to buy Bitcoin (BTC ) for
                    Hope Mountain.
                  </p>
                  <p className=" mb-30 wow fadeInUp" data-wow-delay=".4s">
                    5. Spread the good news, gain more holders and build a
                    strong caring community.
                  </p>
                  <p className=" mb-30 wow fadeInUp" data-wow-delay=".4s">
                    All holders of Hope Time Token who have held for 30 days or
                    more will be able to receive their share of accumulated BTC
                    from Hope Mountain. The more holders the more dollars go to
                    Hope Mountain to buy Bitcoin (BTC.) Me and my team do not
                    receive any reflections or BTC rewards. Please come and
                    start your journey with us and we will see you on Hope
                    Mountain. Together we can join hands and reach our goals and
                    dreams. BTC rewards will be paid monthly to all holders
                    according to the amount of Hope Time Tokens they hold.
                  </p>
                  <p className=" mb-30 wow fadeInUp" data-wow-delay=".4s">
                    PLEASE NOTE BTC WILL BE EXCHANGED TO BTCB FOR DISTRIBUTION
                    AMONGST HOPE TIME TOKEN HOLDERS. ( 1 to 1 ratio)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer
        className="footer pt-100 img-bg"
        style={{
          backgroundImage:
            "linear-gradient(#737ca0 0%, #595d71 4.66%, #65687c 76.69%, #181819 100%)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-6">
              <div
                className="footer-widget mb-60 wow fadeInLeft"
                data-wow-delay=".2s"
              >
                <a href="index.html" className="logo mb-40">
                  <p className="a-navlogo">
                    <Text tid={"projectName"}></Text> Disclaimer
                  </p>
                </a>
                <p className="mb-30" style={{ textAlign: "justify" }}>
                  PLEASE TAKE NOTE THAT WE ARE NOT FINANCIAL ADVISERS AND ANY
                  INVESTMENTS INTO CRYPTO CURRENCY, S CAN BE VERY RISKY. THE
                  CRYPTO MARKET CAN BE EXTREMELY VOLLATILE SO PLEASE DO YOUR OWN
                  RESEARCH. HOWEVER I AS THE FOUNDER AND CREATER OF HOPE TIME
                  TOKEN HAVE MADE AND GIVEN YOU MY PROMISE THAT I WILL KEEP MY
                  WORD TO YOU THE HOLDER AND THE COMMUNITY.
                </p>
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 col-md-6">
              <div
                className="footer-widget mb-60 wow fadeInUp"
                data-wow-delay=".4s"
              ></div>
            </div>
            {/* <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="footer-widget mb-60 wow fadeInUp"
                data-wow-delay=".6s"
              >
                
              </div>
            </div>  */}
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="footer-widget mb-60 wow fadeInRight"
                data-wow-delay=".8s"
              >
                <h4>
                  <Text tid={"contact"}></Text>
                </h4>
                <ul>
                  <li>
                    <p>
                      <br />
                      {/* <Text tid={"contactInfo"}></Text> */}
                      <a
                        href="mailto:team@hopetimetoken.com"
                        className="mail-link"
                      >
                        team@hopetimetoken.com
                      </a>
                      {/* <a
                        href="mailto:steve.hopetimetoken@gmail.com"
                        className="mail-link"
                      >
                        steve.hopetimetoken@gmail.com
                      </a> */}
                    </p>
                    <div className="footer-social-links mt-4 mb-4">
                      <ul>
                        {/* <li>
                      <a href="#">
                        <i className="lni lni-facebook-filled"></i>
                      </a>
                    </li> */}
                        {/* <li>
                          <a
                            href="https://twitter.com/HopeTime_HTME"
                            target="_blank"
                          >
                            <i className="lni lni-twitter-filled"></i>
                          </a>
                        </li> */}
                        {/* <li>
                      <a href="#">
                        <i className="lni lni-linkedin-original"></i>
                      </a>
                    </li> */}
                        {/* <li>
                          <a
                            href="https://t.me/+Gem8rJWBTHZjYmFk"
                            target="_blank"
                          >
                            <i className="lni lni-telegram-original"></i>
                          </a>
                        </li> */}
                        {/* <li>
                      <a href="#">
                        <i className="lni lni-instagram-original"></i>
                      </a>
                    </li> */}
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright-area">
            <p className="mb-0 text-white text-center">
              @ 2023 &nbsp;
              <a href="#" rel="nofollow" target="_blank">
                <Text tid={"projectName"}></Text>
              </a>
            </p>
            <p className="mb-0 text-white text-center color-gold">
              Hope Mountain: &nbsp;
              <a
                href="https://bscscan.com/address/0xFb762E89abF4844C128e33Ce0de0b8936b685bf2"
                rel="nofollow"
                target="_blank"
                className="color-gold "
              >
                0xFb762E89abF4844C128e33Ce0de0b8936b685bf2
              </a>
            </p>
          </div>
        </div>
      </footer>

      <a href="#" className="scroll-top">
        <i className="lni lni-chevron-up"></i>
      </a>
    </div>
  );
}

// export default Home;

Home.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  getRewards: PropTypes.func.isRequired,
  editRewards: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  rewards: state.rewards,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  logout,
  getRewards,
  editRewards,
  setAlert,
})(Home);
